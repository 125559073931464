// src/components/Footer.jsx
import React from 'react';

function Footer({ onAddNew }) {
    return (
        <div className="footer">
            <button onClick={onAddNew}>+ Add New</button>
        </div>
    );
}

export default Footer;
