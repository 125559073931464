import { Close } from '@mui/icons-material';
import { FaClock } from 'react-icons/fa';
import React, { useState, useEffect, useMemo } from 'react';
import ProductHistory from './ProductHistory'; // Import the ProductHistory component
import './Components.css';
import { useSelector } from 'react-redux';
import { db } from '../services/firebaseConfig';
import { collection, updateDoc, addDoc, doc } from 'firebase/firestore';
import { el } from 'date-fns/locale';

function DetailView({ item, onClose }) {
    const [showProductHistory, setShowProductHistory] = useState(false);
    const [showEditItem, setShowEditItem] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // Edit inventory item
    const [showStockIn, setShowStockIn] = useState(false);
    const [showStockOut, setShowStockOut] = useState(false);
    const [stockQuantity, setStockQuantity] = useState(0);
    const [costPrice, setDefaultCostPrice] = useState(0);
    const [sellingPrice, setDefaultSellingPrice] = useState(0);
    const [comments, setComments] = useState('');

    const [isCreatingCategory, setIsCreatingCategory] = useState(false); // State to track if we're creating a new category
    const [newCategory, setNewCategory] = useState('');

    // Initialize state with fallback values to prevent null errors
    // Initialize state with fallback values to prevent null errors
    const [currentExpiryDate, setExpiryDate] = useState(new Date(2999, 11, 31));
    const [productName, setProductName] = useState('');
    const [sku, setSku] = useState('');
    const [barcode, setBarcode] = useState('000000000');
    const [category, setCategory] = useState('None');
    const [initialQuantity, setQuantity] = useState(0);
    const [reorderQuantity, setReorderQuantity] = useState(0);
    const [defaultCostPrice, setCostPrice] = useState(0);
    const [defaultSellingPrice, setSellingPrice] = useState(0);
    const [unitOfMeasure, setUnitOfMeasure] = useState('');
    const [currencyCode, setCurrencyCode] = useState('N/A');


    // Get user data from Redux
    const userEmail = useSelector((state) => state.auth.user?.email);
    const userId = useSelector((state) => state.auth.user?.uid);

    // Memoize the categories to prevent unnecessary re-renders
    const categories = useSelector((state) => state.data.inventory);
    const selectedBusinessId = useSelector((state) => state.data.selectedBusiness);

    const memoizedCategories = useMemo(() => {
        return [...new Set(
            categories
                .filter((item) => item.businessId === selectedBusinessId) // Filter items by businessId
                .map((item) => item.category) // Map to category
        )];
    }, [categories, selectedBusinessId]);

    useEffect(() => {
        if (item) {
            setIsVisible(true);

            // Set form fields with the item details
            setProductName(item.productName || '');
            setSku(item.sku || '');
            setBarcode(item.barcode || '000000000');
            setCategory(item.category || 'None');
            setQuantity(item.initialQuantity || 0);
            setReorderQuantity(item.reorderQuantity || 0);
            setCostPrice(item.defaultCostPrice || 0);
            setSellingPrice(item.defaultSellingPrice || 0);
            setUnitOfMeasure(item.unitOfMeasure || '');
            setCurrencyCode(item.currencyCode || 'N/A');
            setExpiryDate(item.expiryDate ? new Date(item.expiryDate.seconds * 1000) : new Date(2999, 11, 31));
        } else {
            setIsVisible(false);
        }
    }, [item]);

    if (!item) return null;

    // console.log(item);
    // console.log('Currency:', item.currencyCode);

    // Check if the unit allows decimals
    const isDecimalUnit = (unit) => {
        const unitsWithDecimals = [
            'Milliliters (ml)',
            'Liters (l)',
            'Fluid Ounces (fl oz)',
            'Gallons (gal)',
            'Quarts (qt)',
            'Pints (pt)',
            'Cups (cup)',
            'Grams (g)',
            'Kilograms (kg)',
            'Ounces (oz)',
            'Pounds (lb)',
        ];
        return unitsWithDecimals.includes(unit);
    };

    const allowDecimal = isDecimalUnit(item.unitOfMeasure);

    // Utility function to format the timestamp object with seconds and nanoseconds
    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return 'N/A';

        const date = new Date(timestamp.seconds * 1000);
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        }).format(date);
    };

    // Define currency symbol mapping
    const currencySymbols = {
        USD: '$',
        GBP: '£',
        EUR: '€',
        GHS: '₵',
        ZAR: 'R',
        NGN: '₦',
        KES: 'KSh',
        SAR: 'SR',
        AED: 'AED',
        INR: '₹',
        AUD: 'A$',
        CAD: 'C$',
        NZD: 'NZ$',
        SGD: 'S$',
        MYR: 'RM',
        PHP: '₱',
        IDR: 'Rp',
        THB: '฿',
    };

    const currency = item?.currencyCode;
    const currencySymbol = currencySymbols[currency] || '';
    // console.log(item);

    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate Product Name
        if (!productName) {
            alert('Product Name is required');
            return;
        }

        // Validate Category
        if (category === "Create New Category" && !newCategory.trim()) {
            alert('New category is required when selecting "Create New Category".');
            return;
        }

        // If the user chose to create a new category, use the newCategory value
        const finalCategory = category === "Create New Category" ? newCategory.trim() : category;

        // Function to generate SKU based on the product name
        const generateSku = (productName) => {
            const sanitizedProductName = productName.replaceAll(' ', '');
            const randomSuffix = Math.floor(Math.random() * 99999).toString().padStart(5, '0');
            return `${sanitizedProductName.substring(0, 3).toUpperCase()}-${randomSuffix}`;
        };

        // Generate SKU if empty
        const updatedSku = sku.trim() === '' ? generateSku(productName) : sku.trim();

        // Prepare the new item object to be updated in Firestore
        const updatedItem = {
            productName,
            userEmail,
            businessId: selectedBusinessId,
            sku: updatedSku,
            barcode,
            deleted: false,
            category: finalCategory,
            initialQuantity: initialQuantity || 0,
            reorderQuantity: reorderQuantity || 0,
            defaultCostPrice: defaultCostPrice || 0,
            defaultSellingPrice: defaultSellingPrice || 0,
            currencyCode: currencyCode,
            unitOfMeasure,
            shortUnit: unitOfMeasure.split(' ')[1]?.replace(/[()]/g, '') || '',
            dateAdded: item.dateAdded, // Retain the original dateAdded
            expiryDate: currentExpiryDate || new Date('2999-12-31'),
        };

        // Determine the update type and details based on the changes
        let updateType = 'Details Changed';
        const changes = [];

        if (item.productName !== productName) {
            updateType = 'Product Name Change';
            changes.push(`Product Name changed from "${item.productName}" to "${productName}"`);
        }
        if (item.category !== finalCategory) {
            updateType = 'Category Changed';
            changes.push(`Category changed from "${item.category}" to "${finalCategory}"`);
        }
        if (item.barcode !== barcode) {
            updateType = 'Details Changed';
            changes.push(`Barcode changed from "${item.barcode}" to "${barcode}"`);
        }
        if (item.defaultCostPrice !== defaultCostPrice) {
            updateType = 'Details Changed';
            changes.push(`Cost Price changed from "${item.defaultCostPrice}" to "${defaultCostPrice}"`);
        }
        if (item.defaultSellingPrice !== defaultSellingPrice) {
            updateType = 'Details Changed';
            changes.push(`Selling Price changed from "${item.defaultSellingPrice}" to "${defaultSellingPrice}"`);
        }
        if (item.currencyCode !== currencyCode) {
            updateType = 'Details Changed';
            changes.push(`Currency changed from "${item.currencyCode}" to "${currencyCode}"`);
        }
        if (item.unitOfMeasure !== unitOfMeasure) {
            updateType = 'Details Changed';
            changes.push(`Unit of Measure changed from "${item.unitOfMeasure}" to "${unitOfMeasure}"`);
        }
        if (item.reorderQuantity !== reorderQuantity) {
            updateType = 'Reorder Quantity Updated';
            changes.push(`Reorder Quantity changed from "${item.reorderQuantity}" to "${reorderQuantity}"`);
        }

        // Set the details message
        const details = changes.length > 0 ? changes.join(', ') : 'No changes were made.';

        try {
            // Update the inventory item
            await updateDoc(doc(db, 'inventory', item.inventoryItemId), updatedItem);

            // After successful update in inventory, add to inventory_updates
            await addDoc(collection(db, 'inventory_updates'), {
                inventoryItemId: item.inventoryItemId,
                businessId: selectedBusinessId,
                updateType: updateType,
                details: details,
                timestamp: new Date(),
                userId: userId,
                sku: updatedSku,
            });

            alert('Item updated successfully!');
            setShowEditItem(false);
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }
    };




    const handleStockInFormSubmit = async (e) => {
        e.preventDefault();

        if (stockQuantity <= 0) {
            alert('Please enter a valid quantity to add to stock.');
            return;
        }

        try {
            const newQuantity = item.initialQuantity + stockQuantity;

            // Update Firestore only if there's a valid stock in action
            if (stockQuantity > 0) {
                await updateDoc(doc(db, 'inventory', item.inventoryItemId), {
                    initialQuantity: newQuantity,
                });

                // Log the stock-in action to inventory_updates
                await addDoc(collection(db, 'inventory_updates'), {
                    inventoryItemId: item.inventoryItemId,
                    businessId: selectedBusinessId,
                    updateType: 'Stock In',
                    details: comments || `${stockQuantity} items added to the stock.`,
                    timestamp: new Date(),
                    userId: userId,
                    transactionSellingPrice: sellingPrice || item.defaultSellingPrice,
                    newQuantity: newQuantity,
                    quantityChange: stockQuantity,
                    transactionCostPrice: costPrice || item.defaultCostPrice,
                    currencyCode: item.currencyCode,
                    sku: item.sku,
                });

                alert('Stock updated successfully!');
            }

            // Reset the form and close the slide-up
            setShowStockIn(false);
            setStockQuantity(0);
            setComments('');
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }
    };


    const handleStockOutFormSubmit = async (e) => {
        e.preventDefault();

        // Validate if item.initialQuantity is greater than or equal to stockQuantity before accepting
        if (stockQuantity <= 0) {
            alert('Please enter a valid quantity to remove from stock.');
            return;
        }

        if (item.initialQuantity < stockQuantity) {
            alert('Stock quantity cannot be more than the available quantity');
            return;
        }

        try {
            const newQuantity = item.initialQuantity - stockQuantity;

            // Update Firestore only if there's a valid stock out action
            if (stockQuantity > 0) {
                await updateDoc(doc(db, 'inventory', item.inventoryItemId), {
                    initialQuantity: newQuantity,
                });

                // Log the stock-out action to inventory_updates
                await addDoc(collection(db, 'inventory_updates'), {
                    inventoryItemId: item.inventoryItemId,
                    businessId: selectedBusinessId,
                    updateType: 'Stock Out',
                    details: comments || `${stockQuantity} items removed from the stock.`,
                    timestamp: new Date(),
                    userId: userId,
                    transactionSellingPrice: sellingPrice || item.defaultSellingPrice,
                    newQuantity: newQuantity,
                    quantityChange: stockQuantity,
                    transactionCostPrice: costPrice || item.defaultCostPrice,
                    currencyCode: item.currencyCode,
                    sku: item.sku,
                });

                alert('Stock updated successfully!');
            }

            // Reset the form and close the slide-up
            setShowStockOut(false);
            setStockQuantity(0);
            setComments('');
        } catch (error) {
            alert('An error occurred. Please try again.');
            console.error(error);
        }
    };


    return (
        <div className="detail-view-overlay">
            <div className={`detail-view-card ${isVisible ? 'visible' : ''}`}>
                {/* Close Button */}
                <button className="close-button" onClick={() => {
                    setIsVisible(false);
                    setTimeout(() => onClose(), 300);
                }}>
                    <Close />
                </button>

                {/* AppBar (Top Section) */}
                <div className="app-bar">
                    <div>
                        <h2 className="product-title">{item.productName}</h2>
                        <p className="product-category">{item.category}</p>
                    </div>
                    <button className="edit-product-button" onClick={() => setShowEditItem(true)}>Edit Product</button>
                </div>

                {/* Product Information Section */}
                <div className="product-info">
                    <div className="info-item">
                        <h4>Date Added:</h4>
                        <p>{formatDate(item.dateAdded)}</p>
                    </div>
                    <div className="info-item">
                        <h4>Expiry Date:</h4>
                        <p>{formatDate(item.expiryDate)}</p>
                    </div>
                </div>

                {/* Details List */}
                <div className="detail-list">
                    <div className="detail-item">
                        <span>SKU:</span>
                        <strong>{item.sku}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Barcode:</span>
                        <strong>{item.barcode || 'N/A'}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Unit of Measure:</span>
                        <strong>{item.unitOfMeasure || 'N/A'}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Available Quantity:</span>
                        <strong>{item.initialQuantity} {item.unitOfMeasure || ''}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Reorder Quantity:</span>
                        <strong>{item.reorderQuantity} {item.shortUnit}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Selling Price:</span>
                        <strong>{item.currencyCode !== 'N/A' ? `${currencySymbol}${item.defaultSellingPrice}` : `${currencySymbol} ${item.defaultSellingPrice}`}</strong>
                    </div>
                    <div className="divider"></div>

                    <div className="detail-item">
                        <span>Cost Price:</span>
                        <strong>{item.currencyCode !== 'N/A' ? `${currencySymbol}${item.defaultCostPrice}` : `${currencySymbol} ${item.defaultCostPrice}`}</strong>
                    </div>
                </div>

                {/* Product History Section */}
                <div className="history-item" onClick={() => setShowProductHistory(true)} style={{ cursor: 'pointer' }}>
                    <div className="history-icon-container">
                        <FaClock className="history-icon" />
                    </div>
                    <div className="history-text">
                        <span className="history-title">Product history</span>
                        <p className="history-description">Track product changes</p>
                    </div>
                </div>

                {/* Stock In / Stock Out Section */}
                <div className="actions-section">
                    <button className="stock-in-button" onClick={() => setShowStockIn(true)}>Stock in +</button>
                    <button className="stock-out-button" onClick={() => setShowStockOut(true)}>Stock out -</button>
                </div>

            </div>

            {/* Product History Slide-In Panel */}
            {item.inventoryItemId && (
                <div className={`product-history-overlay ${showProductHistory ? 'visible' : 'hidden'}`}>
                    <div className={`slide-in-panel ${showProductHistory ? 'visible' : 'hidden'}`}>
                        <div className="slide-in-header">
                            <button className="close-slide-in" onClick={() => setShowProductHistory(false)}>
                                <Close />
                            </button>
                            <h2>Product History</h2>
                        </div>
                        <ProductHistory
                            inventoryItemId={item.inventoryItemId}
                            onClose={() => setShowProductHistory(false)}
                        />
                    </div>
                </div>
            )}

            {/* Slide-in Panel for Editing an Item */}

            <div className={`product-history-overlay ${showEditItem ? 'visible' : 'hidden'}`}>
                <div className={`slide-in-panel ${showEditItem ? 'visible' : 'hidden'}`}>
                    <div className="slide-in-header">
                        <button className="close-slide-in" onClick={() => setShowEditItem(false)}>
                            <Close />
                        </button>
                        <h2>Edit Inventory Item</h2>
                    </div>
                    <div className="slide-in-content">
                        <form onSubmit={handleFormSubmit}>
                            {/* Product Name */}
                            <div className="form-group">
                                <label>Product Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Product Name"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                    required
                                />
                            </div>

                            {/* SKU/Product Code */}
                            <div className="form-group">
                                <label>SKU/Product Code:</label>
                                <input
                                    type="text"
                                    className="form-control read-only"
                                    placeholder="SKU/Product Code"
                                    value={sku}
                                    onChange={(e) => setSku(e.target.value)}
                                    readOnly={true}
                                />
                            </div>

                            {/* Barcode */}
                            <div className="form-group">
                                <label>Enter Barcode:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="000000000"
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value || '000000000')}
                                />
                            </div>

                            {/* Select Category */}
                            <div className="form-group">
                                <label>Select a category:</label>
                                <select
                                    className="form-control"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                        setIsCreatingCategory(e.target.value === "Create New Category");
                                    }}
                                >
                                    <option value="Create New Category">Create New Category</option>
                                    {['None', ...memoizedCategories].map((cat, index) => (
                                        <option key={index} value={cat}>
                                            {cat}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Add a new category input field */}
                            {isCreatingCategory && (
                                <div className="form-group">
                                    <label>New Category:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter New Category"
                                        value={newCategory}
                                        onChange={(e) => setNewCategory(e.target.value)}
                                    />
                                </div>
                            )}

                            {/* Unit of Measure */}
                            <div className="form-group">
                                <label>Unit of Measure:</label>
                                <select
                                    type="text"
                                    className="form-control"
                                    placeholder="Unit of Measure"
                                    value={unitOfMeasure}
                                    onChange={(e) => setUnitOfMeasure(e.target.value)}
                                >
                                    {[
                                        'Pieces (pcs)',
                                        'Boxes (boxes)',
                                        'Units (units)',
                                        'Liters (l)',
                                        'Kilograms (kg)',
                                        'Items (items)',
                                        'Gallons (gal)',
                                    ].map((unit, index) => (
                                        <option key={index} value={unit}>
                                            {unit}
                                        </option>
                                    ))}
                                </select>

                            </div>

                            {/* <div className="form-group">
                                <label>Initial Quantity:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Initial Quantity"
                                    value={initialQuantity}
                                    onChange={(e) => setQuantity(Number(e.target.value))}
                                />
                            </div> */}

                            <div className="form-group">
                                <label>Reorder Quantity:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Reorder Quantity"
                                    value={reorderQuantity}
                                    onChange={(e) => setReorderQuantity(Number(e.target.value))}
                                />
                            </div>

                            <div className="form-group">
                                <label>Default Cost Price:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Default Cost Price"
                                    value={defaultCostPrice}
                                    onChange={(e) => setCostPrice(Number(e.target.value))}
                                />
                            </div>

                            <div className="form-group">
                                <label>Default Selling Price:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Default Selling Price"
                                    value={defaultSellingPrice}
                                    onChange={(e) => setSellingPrice(Number(e.target.value))}
                                />
                            </div>

                            <div className="form-group">
                                <label>Currency:</label>
                                <select
                                    className="form-control"
                                    value={currencyCode}
                                    onChange={(e) => setCurrencyCode(e.target.value)}
                                >
                                    {['N/A', 'USD', 'GBP', 'EUR', 'GHS', 'ZAR', 'NGN', 'KES', 'SAR', 'AED', 'INR', 'AUD', 'CAD', 'NZD', 'SGD', 'MYR', 'PHP', 'IDR', 'THB'].map((currency, index) => (
                                        <option key={index} value={currency}>
                                            {currency}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Expiry Date:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={currentExpiryDate.toISOString().split('T')[0]}
                                    onChange={(e) => setExpiryDate(new Date(e.target.value))}
                                />
                            </div>

                            {/* Submit Button */}
                            <button type="submit" className="form-submit-button">
                                Update Item
                            </button>
                        </form>
                    </div>
                </div>
            </div>

            {/* Stock In Slide-In Panel */}
            {showStockIn && (
                <div className={`stock-in-overlay ${showStockIn ? 'visible' : 'hidden'}`}>
                    <div className={`slide-up-panel ${showStockIn ? 'visible' : 'hidden'}`}>
                        <div className="slide-up-header">
                            <h2>Stock In</h2>
                            <button className="close-slide-up" onClick={() => setShowStockIn(false)}>
                                <Close />
                            </button>
                        </div>
                        <div className="slide-up-content">
                            <form onSubmit={handleStockInFormSubmit}>
                                {/* Number of Products Input */}
                                <div className="short-form-group">
                                    <label>Enter number of products:</label>
                                    <div className="short-input-group">
                                        <button
                                            type="button"
                                            className="quantity-decrement"
                                            onClick={() => setStockQuantity(Math.max(0, stockQuantity - (allowDecimal ? 0.1 : 1)))}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            step={allowDecimal ? "0.01" : "1"} // Allow up to two decimal places
                                            className="short-form-control"
                                            value={stockQuantity}
                                            onChange={(e) => {
                                                const value = allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
                                                setStockQuantity(Math.max(0, value));
                                            }}
                                            min="0"
                                        />

                                        <button
                                            type="button"
                                            className="quantity-increment"
                                            onClick={() => setStockQuantity(stockQuantity + (allowDecimal ? 0.1 : 1))}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                                {/* Comments Section */}
                                <div className="form-group">
                                    <label>Enter comments:</label>
                                    <textarea
                                        className="short-form-control"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        placeholder="Enter comments"
                                    />
                                </div>
                                <div className="short-form-group-inline">
                                    {/* Cancel Button */}
                                    <button type="button" className="short-form-cancel-button" onClick={() => setShowStockIn(false)}>
                                        Cancel
                                    </button>
                                    {/* Submit Button */}
                                    <button type="submit" className="short-form-submit-button">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {/* Stock Out Slide-In Panel */}
            {showStockOut && (
                <div className={`stock-in-overlay ${showStockOut ? 'visible' : ''}`}>
                    <div className={`slide-up-panel ${showStockOut ? 'visible' : ''}`}>
                        <div className="slide-up-header">
                            <h2>Stock Out</h2>
                            <button className="close-slide-up" onClick={() => setShowStockOut(false)}>
                                <Close />
                            </button>
                        </div>
                        <div className="slide-up-content">
                            <form onSubmit={handleStockOutFormSubmit}>
                                {/* Number of Products Input */}
                                <div className="short-form-group">
                                    <label>Enter number of products:</label>
                                    <div className="short-input-group">
                                        <button
                                            type="button"
                                            className="quantity-decrement"
                                            onClick={() => setStockQuantity(Math.max(0, stockQuantity - (allowDecimal ? 0.1 : 1)))}
                                        >
                                            -
                                        </button>
                                        <input
                                            type="number"
                                            step={allowDecimal ? "0.1" : "1"}
                                            className="short-form-control"
                                            value={stockQuantity}
                                            onChange={(e) => {
                                                const value = allowDecimal ? parseFloat(e.target.value) : parseInt(e.target.value, 10);
                                                setStockQuantity(Math.max(0, value));
                                            }}
                                            min="0"
                                        />
                                        <button
                                            type="button"
                                            className="quantity-increment"
                                            onClick={() => setStockQuantity(stockQuantity + (allowDecimal ? 0.1 : 1))}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                                {/* Comments Section */}
                                <div className="form-group">
                                    <label>Enter comments:</label>
                                    <textarea
                                        className="short-form-control"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        placeholder="Enter comments"
                                    />
                                </div>
                                <div className="short-form-group-inline">
                                    {/* Cancel Button */}
                                    <button type="button" className="short-form-cancel-button" onClick={() => setShowStockOut(false)}>
                                        Cancel
                                    </button>
                                    {/* Submit Button */}
                                    <button type="submit" className="short-form-submit-button">
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DetailView;
