import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businesses: [],
  inventory: [],
  productHistory: {},
  categories: [],
  selectedBusiness: null,
  suppliers: {},
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setBusinesses: (state, action) => {
      state.businesses = action.payload;
    },
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
    setProductHistory: (state, action) => {
      const { inventoryItemId, historyData } = action.payload;
      state.productHistory = {
        ...state.productHistory,
        [inventoryItemId]: historyData,
      };
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedBusiness: (state, action) => {
      state.selectedBusiness = action.payload;
    },
    setSuppliers(state, action) {
      const { businessId, suppliers } = action.payload;
      state.suppliers[businessId] = suppliers;
  },
  setSupplierOrders(state, action) {
    const { supplierId, orders } = action.payload;
    // Find the businessId that contains this supplier
    const businessId = Object.keys(state.suppliers).find(businessId =>
        state.suppliers[businessId].some(supplier => supplier.id === supplierId)
    );

    if (businessId) {
        const supplierIndex = state.suppliers[businessId].findIndex(supplier => supplier.id === supplierId);
        if (supplierIndex !== -1) {
            // Update the specific supplier's orders
            state.suppliers[businessId][supplierIndex] = {
                ...state.suppliers[businessId][supplierIndex],
                orders: orders,
            };
        }
    }
},
    clearData: (state) => {
      state.businesses = [];
      state.inventory = [];
    },
  },
});

export const {
  setBusinesses,
  setInventory,
  clearData,
  setCategories,
  setSelectedBusiness,
  setProductHistory,
  setSuppliers,
  setSupplierOrders,
} = dataSlice.actions;

export default dataSlice.reducer;
