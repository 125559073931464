import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAuth } from "firebase/auth";
import { CloudUpload } from '@mui/icons-material';

function UploadInventoryDialog({ open, onClose }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState('');
    const [processingStatus, setProcessingStatus] = useState('');
    //inventoryiotems from redux
    const existingSkus = useSelector((state) => state.data.inventory.map(item => item.sku));
    const selectedBusinessId = useSelector((state) => state.data.selectedBusiness);
    const userEmail = useSelector((state) => state.auth.user.email);
    const userId = useSelector((state) => state.auth.user.uid);
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // console.log('BaseURL:', baseUrl);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            setError('Please select a file to upload.');
            return;
        }

        setUploading(true);
        setError('');

        try {
            // Get the current user's ID token
            const auth = getAuth();
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setError('User is not authenticated.');
                setUploading(false);
                return;
            }
            const idToken = await currentUser.getIdToken();

            // Get the pre-signed URL for uploading
            const urlResponse = await fetch(`${baseUrl}/api/inventory/generated-upload-url/${selectedBusinessId}/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                },
            });

            if (!urlResponse.ok) {
                throw new Error(await urlResponse.text());
            }

            const { uploadUrl, fileName } = await urlResponse.json();

            // Upload the file to the pre-signed URL
            const response = await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                body: selectedFile,
            });

            if (!response.ok) {
                throw new Error('Failed to upload the file to Google Cloud Storage');
            }

            // Notify backend that the file is ready to be processed
            const processResponse = await fetch(`${baseUrl}/api/inventory/process-upload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${idToken}`,
                },
                body: JSON.stringify({ fileName, businessId: selectedBusinessId, userEmail, userId, existingSkus })
            });

            if (!processResponse.ok) {
                const responseText = await processResponse.text();
                let errorDetails;
                try {
                    const jsonResponse = JSON.parse(responseText);
                    if (jsonResponse.error && jsonResponse.details) {
                        errorDetails = jsonResponse.details.join("\n");
                    } else {
                        errorDetails = jsonResponse.error || responseText;
                    }
                } catch (e) {
                    errorDetails = responseText;
                }

                throw new Error(`Failed to initiate file processing: ${errorDetails}`);
            }

            // Monitor processing status
            setProcessingStatus('Processing...');
            const intervalId = setInterval(async () => {
                try {
                    const actualFileName = fileName.split("/").pop();
                    const statusResponse = await fetch(`${baseUrl}/api/inventory/check-status/${actualFileName}`, {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                        },
                    });

                    if (!statusResponse.ok) {
                        throw new Error(await statusResponse.text());
                    }

                    const { status } = await statusResponse.json();
                    if (status === 'completed') {
                        clearInterval(intervalId);
                        setProcessingStatus('Processing completed successfully!');
                        setUploading(false);
                        onClose();
                    } else if (status === 'failed') {
                        clearInterval(intervalId);
                        setProcessingStatus('Processing failed. Please try again.');
                        setUploading(false);
                    }
                } catch (error) {
                    clearInterval(intervalId);
                    setError('Error checking processing status');
                    setUploading(false);
                }
            }, 5000); // Check status every 5 seconds
        } catch (err) {
            setError(`Error uploading inventory: ${err.message}`);
            setUploading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className='dialog-header'> <CloudUpload /> Upload Inventory</DialogTitle>
            <DialogContent className='dialog-content'>
                <input type="file" accept=".xlsx, .csv" onChange={handleFileChange} />
                {uploading && (
                    <>
                        <LinearProgress variant="determinate" value={uploadProgress} />
                        <Typography variant="caption">{uploadProgress}%</Typography>
                    </>
                )}
                {error && <Typography color="error">{error}</Typography>}
                {processingStatus && <Typography>{processingStatus}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" className='cancel-button'>
                    Cancel
                </Button>
                <Button onClick={handleUpload} color="primary" disabled={uploading} className='upload-button'>
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadInventoryDialog;
