import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, TextField, Avatar, Select, MenuItem, Button } from '@mui/material';
import { Search, CloudUpload, GetApp } from '@mui/icons-material';
import { setSelectedBusiness as setSelectedBusinessAction } from '../store/dataSlice';
import { logout } from '../services/authService';
import UploadInventoryDialog from './UploadInventoryDialog';

function TopBar({ businesses, selectedBusinessId, onBusinessChange, onSearch }) {
    const [localSelectedBusiness, setLocalSelectedBusiness] = useState(selectedBusinessId || '');
    const [selectedBusinessName, setSelectedBusinessName] = useState('Select a Business');
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    //user productCount
    // const productCount = useSelector((state) => state.auth.user?.productCount);
    // console.log('Product Count:', productCount);
    // console.log('user:', user);
    const businessesFromRedux = useSelector((state) => state.data.businesses);
    // console.log('Businesses from Redux:', businessesFromRedux);

    const [isTopBarExpanded, setIsTopBarExpanded] = useState(true);

    const toggleTopBar = () => {
        setIsTopBarExpanded((prev) => !prev);
    };

    useEffect(() => {
        if (!businesses.length && businessesFromRedux.length) {
            // Use the data from Redux if the prop is empty
            setLocalSelectedBusiness(businessesFromRedux[0]?.id);
        }
    }, [businesses, businessesFromRedux]);

    useEffect(() => {
        if (selectedBusinessId) {
            setLocalSelectedBusiness(selectedBusinessId);
            dispatch(setSelectedBusinessAction(selectedBusinessId));
        } else if (businesses.length > 0) {
            // Set the first business as default if none is selected
            setLocalSelectedBusiness(businesses[0].id);
        }
    }, [selectedBusinessId, businesses, dispatch]);


    useEffect(() => {
        // Update the business name based on the selected ID
        const currentBusiness = businesses.find((business) => business.id === localSelectedBusiness);
        if (currentBusiness) {
            setSelectedBusinessName(currentBusiness.businessName);
        } else {
            setSelectedBusinessName('Select a Business');
        }
    }, [localSelectedBusiness, businesses]);

    // console.log('Businesses:', businesses);

    const handleBusinessChange = (event) => {
        const businessId = event.target.value;
        setLocalSelectedBusiness(businessId);
        dispatch(setSelectedBusinessAction(businessId));

        if (onBusinessChange) {
            onBusinessChange(businessId);
        }
    };

    // State to handle dropdown visibility
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Function to handle user logout
    const handleLogout = async () => {
        try {
            await logout();
            // console.log('User logged out successfully');
        } catch (error) {
            // console.error('Logout failed:', error);
            alert('An error occurred during logout. Please try again.');
        }
    };

    // Function to handle the download template button click
    const handleDownloadTemplate = () => {
        // This should open the link to download the template
        window.open('https://storage.googleapis.com/pocketi-inventory.appspot.com/import_template/StockIt_Import_Template_v2024.xlsx', '_blank');
    };

    const handleUploadInventoryClick = () => {
        setIsUploadDialogOpen(true);
    };

    // Function to handle the upload inventory button click
    // const handleUploadInventory = () => {
    //     // Trigger any required modal or upload functionality here
    //     alert('Upload Inventory button clicked!');
    // };

    return (
        <div className={`top-bar ${isTopBarExpanded ? 'expanded' : 'collapsed'}`}>
            {/* Toggle Button for Small Screens */}
            <button className="menu-toggle-button" onClick={toggleTopBar}>
                ☰ {/* This is the "hamburger" icon */}
            </button>

            {/* Collapsible TopBar Content */}
            <div className={`top-bar-content ${isTopBarExpanded ? 'show' : 'hide'}`}>
                {/* Logo */}
                <img
                    src="https://pocketiapp.com/wp-content/uploads/2024/08/stockit_logo.png"
                    alt="Logo"
                    className="logo"
                />

                {/* Business Selector */}
                <Select
                    value={localSelectedBusiness || ''}
                    onChange={handleBusinessChange}
                    displayEmpty
                    className="business-selector"
                    variant="outlined"
                    size="small"
                >
                    <MenuItem value="" disabled>
                        {selectedBusinessName}
                    </MenuItem>
                    {businesses.map((business) => (
                        <MenuItem key={business.id} value={business.id} className="dropdown-container">
                            {business.businessName}
                        </MenuItem>
                    ))}
                </Select>


                {/* Search Input */}
                <div className="search-container">
                    <IconButton>
                        <Search />
                    </IconButton>
                    <TextField
                        placeholder="Search"
                        onChange={(e) => onSearch(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </div>

                {/* New Buttons Section for Download Template and Upload Inventory */}
                <div className="inventory-buttons">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadTemplate}
                        className="download-template-button"
                        startIcon={<GetApp className='download-icon' />} // Download icon
                        style={{ marginRight: '10px' }}
                    >
                        Template
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUploadInventoryClick}
                        className="upload-inventory-button"
                        startIcon={<CloudUpload className='upload-icon' />} // Upload icon
                        style={{ marginRight: '20px' }}
                    >
                        Upload Items
                    </Button>
                </div>

                {/* Profile Section */}
                <div
                    className="profile-section"
                    onMouseEnter={() => setIsDropdownVisible(true)}
                    onMouseLeave={() => setIsDropdownVisible(false)}
                >
                    <p>Welcome, {user?.displayName || user?.full_name || 'User'}</p>

                    <div className="profile-avatar" style={{ cursor: 'pointer' }}>
                        <Avatar
                            alt="User Profile"
                            src={
                                user?.photoURL ||
                                process.env.PUBLIC_URL + '/default_avatar.png' // default avatar image from public folder
                            }
                        />
                    </div>
                    {/* Dropdown for logout */}
                    {isDropdownVisible && (
                        <div
                            className="profile-dropdown"
                            onMouseEnter={() => setIsDropdownVisible(true)}
                            onMouseLeave={() => setIsDropdownVisible(false)}
                        >
                            <button className="logout-button" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
                {/* Upload Inventory Dialog */}
                <UploadInventoryDialog open={isUploadDialogOpen} onClose={() => setIsUploadDialogOpen(false)} />
            </div>
        </div>
    );
}

export default TopBar;
