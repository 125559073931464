import React from 'react';
// component.css
import './Components.css';

function Sidebar({ categories, selectedCategory, onCategorySelect }) {
    return (
        <div className="sidebar">
            <button
                key="suppliers"
                onClick={() => onCategorySelect('suppliers')}
                className={`suppliers-button ${selectedCategory === 'suppliers' ? 'selected' : ''}`}
            >
                Suppliers
            </button>
            {categories.map(({ category, count }) => (
                <button
                    key={category}
                    onClick={() => onCategorySelect(category)}
                    className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                >
                    {category} ({count})
                </button>
            ))}
        </div>
    );
}

export default Sidebar;
