// src/pages/Login.jsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, setSubscriptionTier } from '../store/authSlice';
import { ErrorOutline } from '@mui/icons-material';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    IconButton,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { googleLogin, appleLogin, emailLogin as emailLoginService } from '../services/authService';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebaseConfig';

function isAppleDevice() {
    return /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
}

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize navigate hook
    const dispatch = useDispatch();

    const getFirebaseErrorMessage = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                return "The email address is not valid. Please enter a valid email.";
            case 'auth/user-disabled':
                return "This account has been disabled. Please contact support.";
            case 'auth/user-not-found':
                return "No account found with this email. Please sign up.";
            case 'auth/wrong-password':
                return "Incorrect password. Please try again.";
            case 'auth/email-already-in-use':
                return "This email is already in use. Please try a different email or log in.";
            case 'auth/weak-password':
                return "The password is too weak. Please use a stronger password.";
            case 'auth/network-request-failed':
                return "Network error. Please check your internet connection and try again.";
            default:
                return "An unexpected error occurred. Please try again.";
        }
    };

    const handleLogin = async (loginMethod) => {
        setIsLoading(true);
        try {
            // Step 1: Perform login using the provided method (Google, Apple, Email, etc.)
            const user = await loginMethod();

            // Step 2: Check if login returned an error
            if (user instanceof Error) {
                setError(getFirebaseErrorMessage(user.code));
                setIsLoading(false);
                return;
            }

            // Step 3: Extract basic user information from the user object
            const { uid, email, displayName, photoURL } = user;

            // Step 4: Fetch additional user information from Firestore
            const userDocRef = doc(db, 'users', uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // Extract productCount and businessCount, with default values to prevent undefined values
                const { productCount = 0, businessCount = 0, subscriptionTier = 'free' } = userDoc.data() || {};

                //update user collection with last web login timestamp
                try {
                    await updateDoc(userDocRef, {
                        lastWebLogin: new Date(),
                    });
                    console.log('User last login time updated successfully.');
                } catch (error) {
                    console.error('Error updating user last login time: ', error);
                }
                // Step 5: Dispatch user and subscription information to Redux
                dispatch(setUser({
                    uid,
                    email,
                    displayName,
                    photoURL,
                    productCount,
                    businessCount,
                    subscriptionTier,
                }));

                dispatch(setSubscriptionTier(subscriptionTier));
            } else {
                console.warn('No user document found in Firestore.');
            }

            // Step 6: Navigate to the dashboard after successful login and fetching user data
            navigate('/dashboard');
        } catch (error) {
            setError(getFirebaseErrorMessage(error.code));
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="login-page">
            <div className='login-container'>
                {/* Logo */}
                <img src="https://pocketiapp.com/wp-content/uploads/2024/08/stockit_logo.png" alt="Stockit Logo" className='login-logo' />
                <Container maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ textAlign: 'center', my: 1 }}>
                        <Typography variant="h4" gutterBottom className='login-header-text'>Sign in to manage your inventory</Typography>
                        <Typography variant="body2" color="textSecondary">
                            By continuing, you agree to our{' '}
                            <a href="https://pocketiapp.com/stockit-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a href="https://pocketiapp.com/stockit-privacy-policy" target="_blank" rel="noopener noreferrer">
                                Privacy Policy
                            </a>.
                        </Typography>
                    </Box>
                    <Box component="form" noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            InputLabelProps={{ className: 'text-field-label' }}
                            inputProps={{ style: { fontSize: 12 } }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputLabelProps={{ className: 'text-field-label' }}
                            inputProps={{ style: { fontSize: 12 } }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton onClick={() => setIsPasswordVisible(!isPasswordVisible)} edge="end">
                                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={() => handleLogin(() => emailLoginService(email, password))}
                            disabled={isLoading}
                            className="custom-button"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {isLoading ? <CircularProgress size={24} /> : isSignUp ? 'Sign Up' : 'Continue'}
                        </Button>
                        <Button
                            variant="outlined"
                            fullWidth
                            startIcon={<GoogleIcon />}
                            sx={{ mt: 1, mb: 2 }}
                            onClick={() => handleLogin(googleLogin)}
                        >
                            Continue with Google
                        </Button>

                        {isAppleDevice() && (
                            <Button
                                variant="outlined"
                                fullWidth
                                startIcon={<AppleIcon />}
                                sx={{ mt: 1, mb: 2 }}
                                onClick={() => handleLogin(appleLogin)}
                            >
                                Continue with Apple
                            </Button>
                        )}

                        <Button
                            variant="text"
                            onClick={() => setIsSignUp(!isSignUp)}
                            sx={{ display: 'block', width: '100%', textAlign: 'center' }}
                        >
                            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
                        </Button>
                    </Box>
                    {error && (
                        <Dialog open={!!error} onClose={() => setError('')}>
                            <DialogTitle>
                                <ErrorOutline style={{ color: '#f44336', marginRight: '8px' }} />
                                Something Went Wrong
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" color="textSecondary">
                                    {error}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Please try again or contact support if the problem persists.
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setError('')} color="primary" variant="contained">
                                    Got It
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Container>
            </div>
        </div>
    );
}

export default Login;
