// src/store/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  subscriptionTier: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = {
        uid: action.payload.uid,
        email: action.payload.email,
        displayName: action.payload.displayName,
        photoURL: action.payload.photoURL,
        productCount: action.payload.productCount,
        businessCount: action.payload.businessCount,
      };
    },
    setSubscriptionTier: (state, action) => {
      state.subscriptionTier = action.payload;
    },
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.subscriptionTier = null;
    },
  },
});

export const { setUser, setSubscriptionTier, logoutUser } = authSlice.actions;

export default authSlice.reducer;
