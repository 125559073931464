import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { query, collection, where, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../services/firebaseConfig';
import './Suppliers.css';
import SupplierProfileModal from '../components/SupplierProfileModal';
import { setSelectedBusiness, setSuppliers as setSuppliersInRedux } from '../store/dataSlice';

function Suppliers() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const businessesFromRedux = useSelector((state) => state.data.businesses);
    const selectedBusiness = useSelector((state) => state.data.selectedBusiness);
    const suppliersFromRedux = useSelector((state) => state.data.suppliers);
    const [selectedBusinessName, setSelectedBusinessName] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [viewMode, setViewMode] = useState('grid');
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const rowsPerPage = 10; // Set the number of rows to display per page

    // Pagination Logic
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(suppliers.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentSuppliers = suppliers.slice(startIndex, startIndex + rowsPerPage);

    // console.log("Suppliers from Redux:", suppliersFromRedux);



    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // When selectedBusiness changes, update suppliers based on Redux or Firestore
    useEffect(() => {
        if (selectedBusiness) {
            // Check if suppliers are already in Redux for the selected business
            const suppliersInRedux = suppliersFromRedux[selectedBusiness] || [];

            if (suppliersInRedux.length > 0) {
                // If suppliers are available in Redux, use them
                setSuppliers(suppliersInRedux);
            } else {
                // If not in Redux, fetch from Firestore
                fetchSuppliers(selectedBusiness);
            }
        }
    }, [selectedBusiness, suppliersFromRedux]);

    // Fetch suppliers from Firestore and update both local state and Redux
    const fetchSuppliers = (businessId) => {
        if (!businessId) return;

        const suppliersQuery = query(
            collection(db, 'suppliers'),
            where('businessId', '==', businessId),
            where('deleted', '==', false)
        );

        // Using onSnapshot for real-time updates of suppliers
        const unsubscribeSuppliers = onSnapshot(suppliersQuery, async (snapshot) => {
            // Fetch supplier data
            const fetchedSuppliers = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const supplierData = {
                        id: doc.id,
                        ...doc.data(),
                    };

                    // Fetch orders sub-collection for each supplier
                    const ordersQuery = collection(db, 'suppliers', doc.id, 'orders');
                    const ordersSnapshot = await getDocs(ordersQuery);
                    const fetchedOrders = ordersSnapshot.docs.map((orderDoc) => ({
                        id: orderDoc.id,
                        ...orderDoc.data(),
                    }));

                    // Add the fetched orders to the supplier data
                    return {
                        ...supplierData,
                        orders: fetchedOrders,
                    };
                })
            );

            // Update local state (optional)
            setSuppliers(fetchedSuppliers);

            // Update Redux store
            dispatch(setSuppliersInRedux({ businessId, suppliers: fetchedSuppliers }));
        });

        // Cleanup function to stop listening when not needed
        return () => unsubscribeSuppliers();
    };

    const handleBusinessChange = (businessId) => {
        if (!businessId) return;

        // Dispatch to update selected business in Redux
        dispatch(setSelectedBusiness(businessId));
        setSelectedBusinessName(businessesFromRedux.find((business) => business.id === businessId)?.name || '');
    };

    const handleSupplierClick = (supplier) => {
        setSelectedSupplier(supplier);
        setIsProfileOpen(true);
    };

    const handleCloseProfile = () => {
        setSelectedSupplier(null);
        setIsProfileOpen(false);
    };

    const handleEditSupplier = (supplierId) => {
        console.log(`Editing supplier with ID: ${supplierId}`);
        handleCloseProfile();
    };

    const handleExportSupplier = (format) => {
        console.log(`Exporting supplier data as ${format}`);
        handleCloseProfile();
    };

    return (
        <div className="suppliers-page">
            {/* Main Suppliers Content */}
            <div className="suppliers-content">
                <h2>Suppliers</h2>

                {/* View Toggle Buttons */}
                <div className="view-toggle-buttons">
                    <button
                        className={`left-button ${viewMode === 'grid' ? 'selected-list' : ''}`}
                        onClick={() => setViewMode('grid')}
                    >
                        Grid View
                    </button>
                    <button
                        className={`left-button ${viewMode === 'table' ? 'selected-list' : ''}`}
                        onClick={() => setViewMode('table')}
                    >
                        Table View
                    </button>
                </div>

                {/* Display Suppliers in Grid or Table View */}
                {viewMode === 'grid' ? (
                    <div className="grid-view">
                        <div className="grid-cards">
                            {currentSuppliers.map((supplier) => (
                                <div
                                    key={supplier.id}
                                    className="grid-card"
                                    onClick={() => handleSupplierClick(supplier)}
                                >
                                    <h3>{supplier.name}</h3>
                                    <p><b>Contact:</b> <span className="grid-value">{supplier.contactPerson}</span></p>
                                    <p><b>Email:</b> <span className="grid-value">{supplier.email}</span></p>
                                    <p><b>Phone:</b> <span className="grid-value">{supplier.phone}</span></p>
                                    <p><b>Address:</b> <span className="grid-value">{supplier.address}</span></p>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <table className="suppliers-table">
                        <thead>
                            <tr>
                                <th>Supplier Name</th>
                                <th>Contact Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentSuppliers.map((supplier) => (
                                <tr
                                    key={supplier.id}
                                    onClick={() => handleSupplierClick(supplier)}
                                    className="supplier-row"
                                >
                                    <td>{supplier.name}</td>
                                    <td>{supplier.contactPerson}</td>
                                    <td>{supplier.email}</td>
                                    <td>{supplier.phone}</td>
                                    <td>{supplier.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
                <SupplierProfileModal
                    supplier={selectedSupplier}
                    open={isProfileOpen}
                    onClose={handleCloseProfile}
                    onEdit={handleEditSupplier}
                    onExport={handleExportSupplier}
                />
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages || totalPages === 0}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default Suppliers;
