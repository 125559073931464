import React, { useState } from 'react';
import GridView from './GridView';
import TableView from './TableView';
import { Close } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Components.css';
import { useSelector } from 'react-redux';
import { db } from '../services/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

function ItemList({ items }) {
    const [view, setView] = useState('grid');
    const [showAddItem, setShowAddItem] = useState(false);
    const [expiryDate, setExpiryDate] = useState(null);
    const [productName, setProductName] = useState('');
    const [sku, setSku] = useState('');
    const [barcode, setBarcode] = useState('000000000');
    const [category, setCategory] = useState('Other');
    const [quantity, setQuantity] = useState(null);
    const [reorderQuantity, setReorderQuantity] = useState(null);
    const [costPrice, setCostPrice] = useState(null);
    const [sellingPrice, setSellingPrice] = useState(null);
    const [currency, setCurrency] = useState('N/A');
    const [unitOfMeasure, setUnitOfMeasure] = useState('Pieces (pcs)');
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);

    // Get user data from Redux
    const userEmail = useSelector((state) => state.auth.user?.email);
    const userId = useSelector((state) => state.auth.user?.uid);
    const selectedBusinessId = useSelector((state) => state.data.selectedBusiness);
    //categories where each category is unique and businessId mataches the selectedBusinessId
    const categories = [...new Set(items.filter((item) => item.businessId === selectedBusinessId).map((item) => item.category))];


    //console log items
    // console.log('Items:', items);
    // Function to generate SKU based on the product name
    const generateSku = (productName) => {
        const sanitizedProductName = productName.replaceAll(' ', '');
        const randomSuffix = Math.floor(Math.random() * 99999).toString().padStart(5, '0');
        return `${sanitizedProductName.substring(0, 3).toUpperCase()}-${randomSuffix}`;
    };

    // Check if the unit allows decimals
    const isDecimalUnit = (unit) => {
        const unitsWithDecimals = [
            'Milliliters (ml)',
            'Liters (l)',
            'Fluid Ounces (fl oz)',
            'Gallons (gal)',
            'Quarts (qt)',
            'Pints (pt)',
            'Cups (cup)',
            'Grams (g)',
            'Kilograms (kg)',
            'Ounces (oz)',
            'Pounds (lb)',
        ];
        return unitsWithDecimals.includes(unit);
    };




    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate Product Name
        if (!productName) {
            alert('Product Name is required');
            return;
        }

        // Generate SKU if empty
        // Generate SKU if empty
        const updatedSku = sku.trim() === '' ? generateSku(productName) : sku.trim();

        // Prepare the new item object to be added to Firestore
        const newItem = {
            productName,
            userEmail,
            businessId: selectedBusinessId,
            sku: updatedSku,
            barcode,
            deleted: false,
            category,
            initialQuantity: quantity || 0,
            reorderQuantity: reorderQuantity || 0,
            defaultCostPrice: costPrice || 0,
            defaultSellingPrice: sellingPrice || 0,
            currencyCode: currency,
            unitOfMeasure,
            shortUnit: unitOfMeasure.split(' ')[1].replace(/[()]/g, ''),
            dateAdded: new Date(),
            expiryDate: expiryDate || new Date('2999-12-31'),
        };

        try {
            // Add the new item to Firestore
            const docRef = await addDoc(collection(db, 'inventory'), newItem);

            // After successful addition to inventory, add to inventory_updates
            await addDoc(collection(db, 'inventory_updates'), {
                inventoryItemId: docRef.id, // Use the Firestore document ID as inventoryItemId
                businessId: selectedBusinessId,
                updateType: 'Product Added',
                details: `${productName}: Added to Inventory`,
                timestamp: new Date(),
                userId: userId,
                sku: updatedSku,
            });

            // Show alert to user
            alert('Item added successfully!');

            // Reset form fields after submission
            setProductName('');
            setSku('');
            setBarcode('000000000');
            setCategory('None');
            setQuantity(0);
            setReorderQuantity(0);
            setCostPrice(0);
            setSellingPrice(0);
            setCurrency('N/A');
            setExpiryDate(new Date('2999-12-31'));
            setShowAddItem(false);
        } catch (error) {
            console.error('Error adding item: ', error);
            alert('Failed to add item. Please try again.');
        }
    };

    return (
        <div className="item-list">
            <div className="button-container">
                <div className="left-buttons">
                    <button
                        onClick={() => setView('grid')}
                        className={`left-button ${view === 'grid' ? 'selected-list' : ''}`}
                    >
                        Grid View
                    </button>
                    <button
                        onClick={() => setView('table')}
                        className={`left-button ${view === 'table' ? 'selected-list' : ''}`}
                    >
                        Table View
                    </button>
                </div>
                <button className="add-item-button" onClick={() => setShowAddItem(true)}>
                    Add a new item +
                </button>
            </div>

            {view === 'grid' ? <GridView items={items} /> : <TableView items={items} />}

            {/* Slide-in Panel for adding an item */}
            <div className={`product-history-overlay ${showAddItem ? 'visible' : 'hidden'}`}>
                <div className={`slide-in-panel ${showAddItem ? 'visible' : 'hidden'}`}>
                    <div className="slide-in-header">
                        <button className="close-slide-in" onClick={() => setShowAddItem(false)}>
                            <Close />
                        </button>
                        <h2>Add Inventory Item</h2>
                    </div>
                    <div className="slide-in-content">
                        <form onSubmit={handleFormSubmit}>
                            {/* Product Name */}
                            <div className="form-group">
                                <label>Product Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Product Name"
                                    value={productName}
                                    onChange={(e) => setProductName(e.target.value)}
                                    required
                                />
                            </div>

                            {/* SKU/Product Code */}
                            <div className="form-group">
                                <label>SKU/Product Code:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="SKU/Product Code"
                                    value={sku}
                                    onChange={(e) => setSku(e.target.value)}
                                />
                            </div>

                            {/* Barcode */}
                            <div className="form-group">
                                <label>Enter Barcode:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="000000000"
                                    value={barcode}
                                    onChange={(e) => setBarcode(e.target.value || '000000000')}
                                />
                            </div>

                            {/* Select Category */}
                            <div className="form-group">
                                <label>Select a category:</label>
                                <select
                                    className="form-control"
                                    value={category}
                                    onChange={(e) => {
                                        setCategory(e.target.value);
                                        setIsCreatingCategory(e.target.value === "Create New Category");
                                    }}
                                >
                                    <option value="Create New Category">Create New Category</option>

                                    {['None', ...categories].map((cat, index) => (
                                        <option key={index} value={cat}>
                                            {cat}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Add a new category input field */}
                            {isCreatingCategory && (
                                <div className="form-group">
                                    <label>New Category:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter New Category"
                                        value={category === "Create New Category" ? "" : category}
                                        onChange={(e) => setCategory(e.target.value)}
                                    />
                                </div>
                            )}

                            {/* Select Unit of Measure */}
                            <div className="form-group">
                                <label>Select a unit of measure:</label>
                                <select
                                    className="form-control"
                                    value={unitOfMeasure}
                                    onChange={(e) => setUnitOfMeasure(e.target.value)}
                                >
                                    {[
                                        'Pieces (pcs)',
                                        'Boxes (boxes)',
                                        'Units (units)',
                                        'Liters (l)',
                                        'Kilograms (kg)',
                                        'Items (items)',
                                        'Gallons (gal)',
                                        'Packs (packs)',
                                        'Milliliters (ml)',
                                        'Ounces (oz)',
                                        'Pints (pt)',
                                        'Quarts (qt)',
                                        'Cups (cup)',
                                    ].map((unit, index) => (
                                        <option key={index} value={unit}>
                                            {unit}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Initial Quantity */}
                            <div className="form-group">
                                <label>Initial Quantity:</label>
                                <input
                                    type="number"
                                    step={isDecimalUnit(unitOfMeasure) ? "0.01" : "1"} // Allow decimals if needed
                                    className="form-control"
                                    placeholder="0"
                                    value={quantity}
                                    onChange={(e) => {
                                        // Allow decimals if unit requires it, else enforce whole numbers
                                        const parsedValue = parseFloat(e.target.value || '0');
                                        setQuantity(isDecimalUnit(unitOfMeasure) ? parsedValue : Math.max(0, parseInt(e.target.value || '0')));
                                    }}
                                />
                            </div>

                            {/* Reorder Quantity */}
                            <div className="form-group">
                                <label>Reorder Quantity:</label>
                                <input
                                    type="number"
                                    step={isDecimalUnit(unitOfMeasure) ? "0.01" : "1"} // Allow decimals if needed
                                    className="form-control"
                                    placeholder="0"
                                    value={reorderQuantity}
                                    onChange={(e) => {
                                        // Allow decimals if unit requires it, else enforce whole numbers
                                        const parsedValue = parseFloat(e.target.value || '0');
                                        setReorderQuantity(isDecimalUnit(unitOfMeasure) ? parsedValue : Math.max(0, parseInt(e.target.value || '0')));
                                    }}
                                />
                            </div>

                            {/* Reorder Quantity */}
                            <div className="form-group">
                                <label>Reorder Quantity:</label>
                                <input
                                    type="number"
                                    step={isDecimalUnit(unitOfMeasure) ? "0.01" : "1"} // Allow decimals if needed
                                    className="form-control"
                                    placeholder="0"
                                    value={reorderQuantity}
                                    onChange={(e) => {
                                        // Allow decimals if unit requires it, else enforce whole numbers
                                        const parsedValue = parseFloat(e.target.value || '0');
                                        setReorderQuantity(isDecimalUnit(unitOfMeasure) ? parsedValue : Math.max(0, parseInt(e.target.value || '0')));
                                    }}
                                />
                            </div>

                            {/* Cost Price */}
                            <div className="form-group">
                                <label>Cost Price:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0"
                                    value={costPrice}
                                    onChange={(e) => setCostPrice(Math.max(0, parseFloat(e.target.value || '0')))}
                                />
                            </div>

                            {/* Selling Price */}
                            <div className="form-group">
                                <label>Selling Price:</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="0"
                                    value={sellingPrice}
                                    onChange={(e) => setSellingPrice(Math.max(0, parseFloat(e.target.value || '0')))}
                                />
                            </div>

                            {/* Currency */}
                            <div className="form-group">
                                <label>Currency:</label>
                                <select
                                    className="form-control"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {[
                                        'N/A',
                                        'USD',
                                        'EUR',
                                        'GBP',
                                        'GHS',
                                        'SAR',
                                        'JPY',
                                        'AUD',
                                        'CAD',
                                        'ZAR',
                                        'INR',
                                        'CNY',
                                        'AED',
                                        'NGN',
                                        'KES',
                                        'UGX',
                                        'TZS',
                                        'CHF',
                                        'RWF',
                                        'None',
                                    ].map((currency, index) => (
                                        <option key={index} value={currency}>
                                            {currency}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Expiry Date */}
                            <div className="form-group">
                                <label>Expiry Date:</label>
                                <DatePicker
                                    selected={expiryDate}
                                    onChange={(date) => setExpiryDate(date)}
                                    className="form-control"
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText="Select Expiry Date"
                                />
                            </div>
                            {/* Submit Button */}
                            <button type="submit" className="form-submit-button">
                                Add Item
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemList;
