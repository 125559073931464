// src/services/authService.js
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider, 
  OAuthProvider,
  signInWithCredential,
} from 'firebase/auth';


const auth = getAuth();

// Login with Email and Password
export const emailLogin = async (email, password) => {
try {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  return userCredential.user;
} catch (error) {
  return error; // Return error if login fails
}
};

// console.log('CLIENT_ID:', process.env.REACT_APP_GOOGLE_CLIENT_ID);
// Google Login
export const googleLogin = async () => {
  // console.log('Initializing Google Login...');
  try {
    return new Promise((resolve, reject) => {
      /* global google */
      // console.log('Checking if Google Identity Services library is loaded...');
      if (typeof google === 'undefined' || !google.accounts) {
        // console.error('Google Identity Services library is not loaded.');
        reject('Google Identity Services library is not loaded');
        return;
      }

      // console.log('Initializing GIS with client_id...');
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: async (response) => {
          // console.log('GIS callback triggered. Response:', response);
          if (!response || !response.credential) {
            // console.error('Invalid GIS response. Missing credential.');
            reject('Invalid GIS response. Missing credential.');
            return;
          }

          try {
            // console.log('Exchanging ID token with Firebase...');
            const idToken = response.credential;
            const auth = getAuth();
            const credential = GoogleAuthProvider.credential(idToken);
            const userCredential = await signInWithCredential(auth, credential);

            // console.log('Firebase sign-in successful. User:', userCredential.user);
            resolve(userCredential.user); // Return user object
          } catch (error) {
            // console.error('Error during Firebase authentication:', error);
            reject(error);
          }
        },
      });

      // console.log('Prompting user with Google Sign-In...');
      google.accounts.id.prompt();
    });
  } catch (error) {
    // console.error('Google Login Error:', error);
    throw error;
  }
};



// Apple Login
export const appleLogin = async () => {
try {
  const provider = new OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');

  const userCredential = await signInWithPopup(auth, provider);
  return userCredential.user;
}
catch (error) {
  return error; // Return error if login fails
}

};


// Logout
export const logout = async () => {
try {
  await auth.signOut();
  //return to logout page
  window.location.href = '/';
} catch (error) {
  return error; // Return error if logout fails
}
};

// Get Current User
export const getCurrentUser = () => {
return auth.currentUser;
};