import React, { useState } from 'react';
import { TbEdit, TbSearch } from 'react-icons/tb';
import DetailView from './DetailView';

function TableView({ items }) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Set the number of rows to display per page

    const toggleRowExpansion = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    // console.log('Items:', items);

    const closeDetailView = () => {
        setSelectedItem(null);
    }

    const handleEditClick = (item) => {
        setSelectedItem(item);
    }

    const handleViewClick = (item) => {
        setSelectedItem(item);
    }

    // Sort the full list by dateAdded before pagination
    const sortedItems = items.slice().sort((a, b) => b.dateAdded.seconds - a.dateAdded.seconds);


    // Pagination Logic
    const totalPages = Math.ceil(items.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentItems = sortedItems.slice(startIndex, startIndex + rowsPerPage);


    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Utility function to format the Firestore timestamp
    const formatDate = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return 'N/A'; // Handle cases where timestamp is invalid

        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        }).format(date);
    };

    // Short date format DD MMM YYYY
    const shortDateFormat = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return 'N/A'; // Handle cases where timestamp is invalid

        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }).format(date);
    };

    // Define currency symbol mapping
    const currencySymbols = {
        USD: '$',
        GBP: '£',
        EUR: '€',
        GHS: '₵',
        ZAR: 'R',
        NGN: '₦',
        KES: 'KSh',
        SAR: 'SR',
        AED: 'AED',
        INR: '₹',
        AUD: 'A$',
        CAD: 'C$',
        NZD: 'NZ$',
        SGD: 'S$',
        MYR: 'RM',
        PHP: '₱',
        IDR: 'Rp',
        THB: '฿',
    };

    return (
        <>
            <table className="table-view">
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>SKU</th>
                        <th>Quantity</th>
                        <th>Category</th>
                        <th>Date Added</th>
                        <th>Action</th>  {/* Add Edit Product Button */}
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => {
                        const currencyCode = item.currencyCode;
                        const currencySymbol = currencySymbols[currencyCode] || '';

                        return (

                            <React.Fragment key={item.id}> {/* Use `item.id` instead of `index` for a more stable key */}
                                {/* Main Row */}


                                <tr onClick={() => toggleRowExpansion(index)}>
                                    <td>{item.productName}</td>
                                    <td>{item.sku}</td>
                                    <td><strong>{item.initialQuantity}</strong> {item.shortUnit}</td> {/* Make Quantity Bold */}
                                    <td className="category-table-item">{item.category}</td>
                                    <td>{shortDateFormat(item.dateAdded)}</td>
                                    <td>
                                        <TbEdit className="edit-icon" onClick={(e) => {
                                            e.stopPropagation();
                                            handleEditClick(item);
                                        }} />
                                        <TbSearch className="view-icon" onClick={(e) => {
                                            e.stopPropagation();
                                            handleViewClick(item);
                                        }} />
                                    </td>
                                </tr>

                                {/* Expanded Row */}
                                {expandedRow === index && (
                                    <tr className="expanded-row">
                                        <td colSpan="6"> {/* Make sure the colspan matches your table column count */}
                                            <div className="expanded-row-content">
                                                <p><strong>SKU:</strong> {item.sku}</p>
                                                <p><strong>Barcode:</strong> {item.barcode || 'N/A'}</p>
                                                <p><strong>Unit of Measure:</strong> {item.unitOfMeasure || 'N/A'}</p>
                                                <p><strong>Available Quantity:</strong> {item.initialQuantity} {item.shortUnit}</p>
                                                <p><strong>Reorder Quantity:</strong> {item.reorderQuantity} pcs</p>
                                                <p><strong>Selling Price:</strong> {currencyCode !== 'N/A' ? `${currencySymbol}${item.defaultSellingPrice}` : `${currencySymbol} N/A`}</p>
                                                <p><strong>Cost Price:</strong> {currencyCode !== 'N/A' ? `${currencySymbol}${item.defaultCostPrice}` : `${currencySymbol} N/A`}</p>
                                                <p><strong>Date Added:</strong> {formatDate(item.dateAdded)}</p>
                                                <p><strong>Expiry Date:</strong> {formatDate(item.expiryDate)}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>

            {/* Detailed view section */}
            <div className="table-view">
                <DetailView item={selectedItem} onClose={closeDetailView} />
            </div>
        </>
    );
}

export default TableView;
