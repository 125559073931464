import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, Select } from '@mui/material';
import './SupplierProfileModal.css'; // Import your custom CSS
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const SupplierProfileModal = ({ supplier, open, onClose, onEdit, onExport }) => {
    // Hooks must be defined at the top of the component body
    const rowsPerPage = 5;

    // State hooks
    const [dateFilter, setDateFilter] = useState('This Month');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);



    const handleExportSupplier = (type) => {
        const exportData = filteredOrders.map(order => ({
            'Product Name': order.productName,
            Quantity: `${order.quantity} ${order.shortUnit}`,
            Cost: `$${order.cost}`,
            'Invoice Number': order.invoiceNumber,
            'Date Ordered': shortDateFormat(order.dateAdded),
        }));

        if (type === 'excel') {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
            XLSX.writeFile(workbook, `${supplier.name}_Orders.xlsx`);
        } else if (type === 'pdf') {
            // Create the PDF document in landscape mode
            const doc = new jsPDF('l', 'px', 'a4');

            // Determine the date range for the title
            const { start, end } = getDateRange(dateFilter); // Use your getDateRange function
            const formattedStartDate = new Intl.DateTimeFormat('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }).format(start);
            const formattedEndDate = new Intl.DateTimeFormat('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }).format(end);

            // Generate title with the date range
            const title = `${supplier.name} - Order History (${formattedStartDate} to ${formattedEndDate})`;

            // Add some top margin before the title
            const topMargin = 20; // Adjust this value for the desired margin
            doc.text(title, 14, topMargin);

            // Add the table with a startY value that includes the title's height and additional margin
            doc.autoTable({
                head: [['Product Name', 'Quantity', 'Cost', 'Invoice Number', 'Date Ordered']],
                body: exportData.map(row => Object.values(row)),
                startY: topMargin + 10, // Adjust this value to set the gap between title and table
            });

            // Save the PDF file
            doc.save(`${supplier.name}_Orders.pdf`);
        }

    };

    // Utility function to get the date range based on the selected filter
    const getDateRange = (filter) => {
        const now = new Date();
        let start, end;

        switch (filter) {
            case 'Last Month':
                start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                end = new Date(now.getFullYear(), now.getMonth(), 0);
                break;
            case 'This Year':
                start = new Date(now.getFullYear(), 0, 1);
                end = now;
                break;
            case 'Last Year':
                start = new Date(now.getFullYear() - 1, 0, 1);
                end = new Date(now.getFullYear() - 1, 11, 31);
                break;
            case 'This Week':
                start = new Date();
                start.setDate(now.getDate() - now.getDay());
                end = now;
                break;
            case 'This Month':
            default:
                start = new Date(now.getFullYear(), now.getMonth(), 1);
                end = now;
                break;
        }
        return { start, end };
    };

    // Filtering Orders based on Date Filter
    useEffect(() => {
        if (supplier && supplier.orders) {
            // console.log("Supplier Orders:", supplier.orders); // Debugging to verify the orders array

            const { start, end } = getDateRange(dateFilter);
            // console.log("Filtering orders with date range:", start, end); // Debugging the date range

            const filtered = supplier.orders.filter(order => {
                // Convert Firestore Timestamp to Date object
                const orderDate = order.dateAdded && typeof order.dateAdded.toDate === 'function'
                    ? order.dateAdded.toDate()
                    : new Date(order.dateAdded.seconds * 1000); // Fallback in case toDate() isn't available

                // Debug the order date conversion
                // console.log("Order Date:", orderDate);

                // Check if orderDate is valid and falls within the range
                if (!orderDate || isNaN(orderDate.getTime())) {
                    console.error("Invalid date:", order.dateAdded); // Debugging invalid date
                    return false;
                }

                return orderDate >= start && orderDate <= end;
            });

            // console.log("Filtered Orders Length:", filtered.length); // Debugging filtered orders length
            setFilteredOrders(filtered);
            setCurrentPage(1); // Reset to first page whenever the filter changes
        }
    }, [supplier, dateFilter]);

    // Pagination Logic
    const totalPages = Math.ceil(filteredOrders.length / rowsPerPage);
    const startIndex = (currentPage - 1) * rowsPerPage;
    const currentOrders = filteredOrders.slice(startIndex, startIndex + rowsPerPage);

    //console log orders 
    // console.log("Current Orders:", currentOrders);


    // Pagination Handlers
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle Dropdown Change
    const handleDateFilterChange = (event) => {
        setDateFilter(event.target.value);
    };

    // Utility function to format dates (with short date format)
    const shortDateFormat = (timestamp) => {
        if (!timestamp || !timestamp.seconds) return 'N/A'; // Handle cases where timestamp is invalid

        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return new Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        }).format(date);
    };
    // Render null if supplier is not defined
    if (!supplier) return null;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle className='supplier-popup-header'>
                {supplier.name}
                <Button onClick={onClose} style={{ float: 'right' }}>
                    Close
                </Button>
            </DialogTitle>
            <DialogContent dividers>
                {/* Supplier Details */}
                <div className="supplier-details-card">
                    <Typography variant="h6" gutterBottom>
                        SUPPLIER DETAILS
                    </Typography>
                    <Divider />
                    <div className="supplier-detail-row">
                        <Typography variant="body1" className="supplier-detail-title">
                            Contact Person:
                        </Typography>
                        <Typography variant="body1" className="supplier-detail-value">
                            {supplier.contactPerson || 'No contact provided'}
                        </Typography>
                    </div>
                    <div className="supplier-detail-row">
                        <Typography variant="body1" className="supplier-detail-title">
                            Email:
                        </Typography>
                        <Typography variant="body1" className="supplier-detail-value">
                            {supplier.email || 'No email provided'}
                        </Typography>
                    </div>
                    <div className="supplier-detail-row">
                        <Typography variant="body1" className="supplier-detail-title">
                            Phone:
                        </Typography>
                        <Typography variant="body1" className="supplier-detail-value">
                            {supplier.phone || 'No phone provided'}
                        </Typography>
                    </div>
                    <div className="supplier-detail-row">
                        <Typography variant="body1" className="supplier-detail-title">
                            Address:
                        </Typography>
                        <Typography variant="body1" className="supplier-detail-value">
                            {supplier.address || 'No address provided'}
                        </Typography>
                    </div>
                </div>

                {/* Orders Table with Filter */}
                <div className="order-history-card">
                    <div className="order-history-header">
                        <Typography variant="h6" gutterBottom style={{ marginTop: '8px', display: 'inline-block' }}>
                            ORDER HISTORY
                        </Typography>
                        <Select
                            value={dateFilter}
                            onChange={handleDateFilterChange}
                            style={{ marginLeft: '16px' }}
                            sx={{
                                '& .MuiSelect-select': {
                                    padding: '8px 32px 8px 8px !important', // Increase padding for better readability
                                    //font size 12px
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#ffffff',
                                    backgroundColor: '#F57C11',
                                    borderRadius: '24px',

                                },
                            }}
                        >
                            <MenuItem value="This Month" >This Month</MenuItem>
                            <MenuItem value="Last Month" >Last Month</MenuItem>
                            <MenuItem value="This Week" >This Week</MenuItem>
                            <MenuItem value="This Year" >This Year</MenuItem>
                            <MenuItem value="Last Year" >Last Year</MenuItem>
                        </Select>
                    </div>
                    <Divider />
                    <TableContainer component={Paper} style={{ marginTop: '16px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell><b>Order ID</b></TableCell> */}
                                    <TableCell><b>Product Name</b></TableCell>
                                    <TableCell><b>Quantity</b></TableCell>
                                    <TableCell><b>Cost</b></TableCell>
                                    <TableCell><b>Invoice Number</b></TableCell>
                                    <TableCell><b>Date Added</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentOrders.length > 0 ? (
                                    currentOrders.map((order) => (
                                        <TableRow key={order.id}>
                                            {/* <TableCell>{order.orderId}</TableCell> */}
                                            <TableCell>{order.productName}</TableCell>
                                            <TableCell>{order.quantity} {order.shortUnit}</TableCell>
                                            <TableCell>${order.cost}</TableCell>
                                            <TableCell>{order.invoiceNumber}</TableCell>
                                            <TableCell>{shortDateFormat(order.dateAdded)}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No orders available for this supplier.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination Controls */}
                    <div className="pagination-controls">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages || totalPages === 0}>
                            Next
                        </button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleExportSupplier('excel')} color="primary" variant="contained">
                    Export to Excel
                </Button>
                <Button onClick={() => handleExportSupplier('pdf')} color="primary" variant="contained">
                    Export to PDF
                </Button>
                <Button onClick={() => onEdit(supplier.id)} color="secondary" variant="contained">
                    Edit Supplier
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupplierProfileModal;
