import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBusinesses, setInventory } from '../store/dataSlice';
import TopBar from '../components/TopBar';
import DashboardOverview from '../components/DashboardOverview';
import { Close } from '@mui/icons-material';
import Sidebar from '../components/Sidebar';
import DetailView from '../components/DetailView';
import ItemList from '../components/ItemList';
import ReportingPanel from '../components/ReportingPanel';
import Footer from '../components/Footer';
import { fetchUserData } from '../services/fetchUserData'; // Fetch function for Firestore
import './Dashboard.css'; // CSS for layout styling
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../services/firebaseConfig';
import Suppliers from './Suppliers';

function Dashboard() {
    const user = useSelector((state) => state.auth.user); // Get logged-in user from Redux
    const businessesFromRedux = useSelector((state) => state.data.businesses);
    const inventoryFromRedux = useSelector((state) => state.data.inventory);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedBusinessName, setSelectedBusinessName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [businesses, setBusinessesState] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    // console.log('Businesses:', businessesFromRedux);
    // console.log('Selected Business:', selectedBusiness);
    useEffect(() => {
        if (!user) return;

        setIsLoading(true);

        // Set up real-time listeners using fetchUserData
        const unsubscribe = fetchUserData(user, ({ type, data }) => {
            if (type === 'businesses') {
                const activeBusinesses = data.filter((business) => business.deleted === false);
                dispatch(setBusinesses(activeBusinesses));

                // Set default business if none is selected
                if (activeBusinesses.length > 0 && !selectedBusiness) {
                    setSelectedBusiness(activeBusinesses[0].id);
                    setSelectedBusinessName(activeBusinesses[0].businessName);
                }
            } else if (type === 'inventory') {
                dispatch(setInventory(data));
            }
        });

        // Cleanup the listener on component unmount
        return () => unsubscribe && unsubscribe();
    }, [user, dispatch, selectedBusiness]);

    useEffect(() => {
        // Update items, categories, and summary data when inventory or business changes
        if (selectedBusiness && inventoryFromRedux.length > 0) {
            updateData(inventoryFromRedux, selectedBusiness);
        }
    }, [selectedBusiness, inventoryFromRedux]);



    const fetchInventoryForBusiness = async (user, businessId) => {
        if (!user || !businessId) return;

        // Query inventory for the selected business only
        const inventoryQuery = query(
            collection(db, 'inventory'),
            where('userEmail', '==', user.email),
            where('businessId', '==', businessId),
            where('deleted', '==', false)
        );

        const inventorySnapshot = await getDocs(inventoryQuery);
        const inventory = inventorySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        dispatch(setInventory(inventory));
        updateData(inventory, businessId);
    };


    // Function to update items, categories, and summary data based on the selected business
    const updateData = (inventory, businessId) => {
        if (!businessId) return;

        const filteredInventory = inventory.filter((item) => item.businessId === businessId && item.deleted === false);
        setItems(filteredInventory);

        // Generate categories with count
        const categoryCounts = filteredInventory.reduce((counts, item) => {
            counts[item.category] = (counts[item.category] || 0) + 1;
            return counts;
        }, {});

        // Add "All" category with the total count of items
        const allCategory = { category: 'All', count: filteredInventory.length };
        const categoriesWithCounts = [allCategory, ...Object.keys(categoryCounts).map(category => ({
            category,
            count: categoryCounts[category]
        }))];

        setCategories(categoriesWithCounts);

        const totalItems = filteredInventory.length;
        const outOfStockCount = filteredInventory.filter((item) => item.initialQuantity <= 0).length;
        const lowStockCount = filteredInventory.filter((item) => item.initialQuantity > 0 && item.initialQuantity <= 5).length;
        const expiredItemsCount = filteredInventory.filter((item) => item.expiryDate < new Date()).length;

        setSummaryData([
            { label: 'Out of Stock', value: outOfStockCount },
            { label: 'Total Items', value: totalItems },
            { label: 'Low Stock Alerts', value: lowStockCount },
            { label: 'Expired Items', value: expiredItemsCount },
        ]);
    };

    const handleBusinessChange = (businessId) => {
        if (!businessId) return;

        // Check if the inventory for the selected business is already in Redux
        const inventoryForSelectedBusiness = inventoryFromRedux.filter(
            (item) => item.businessId === businessId && item.deleted === false
        );

        if (inventoryForSelectedBusiness.length > 0) {
            // If inventory is already in Redux, update the state directly
            setSelectedBusiness(businessId);
            setSelectedBusinessName(businessesFromRedux.find((business) => business.id === businessId)?.name || '');
            setSelectedCategory('All'); // Reset category to "All"
            updateData(inventoryForSelectedBusiness, businessId); // Update items, categories, etc.
        } else {
            // If inventory is not in Redux, fetch it from Firestore
            fetchInventoryForBusiness(user, businessId);
        }
    };


    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };

    const handleExport = (reportTitle) => alert(`Exporting ${reportTitle}`);

    // Filter items based on the selected category
    const filteredItems = selectedCategory === 'All' ? items : items.filter((item) => item.category === selectedCategory);

    // Function to handle search input
    const handleSearch = (query) => {
        if (query) {
            const foundItems = items.filter(
                (item) =>
                    item.productName.toLowerCase().includes(query.toLowerCase()) ||
                    item.sku.toLowerCase().includes(query.toLowerCase()) // Optionally search by SKU or other fields
            );
            setSearchResults(foundItems);
        } else {
            setSearchResults([]);
        }
    };

    //SearchResultsComponent function
    const searchResultsRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
                setSearchResults([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [searchResultsRef]);

    //add listener for click outside of search results




    return (

        <div className="dashboard">
            {/* Top Bar */}
            <>
                <TopBar
                    businesses={businessesFromRedux}
                    selectedBusinessId={selectedBusiness}
                    selectedBusinessName={selectedBusinessName}
                    onBusinessChange={handleBusinessChange}
                    onSearch={handleSearch}
                />

                {/* Display search results when there are matches */}
                {searchResults.length > 0 && (
                    <div ref={searchResultsRef} className="search-results">
                        <div className='search-header'>
                            <h4>Search Results:</h4>
                            {/* Close Button */}
                            <button className="close-button" onClick={() => setSearchResults([])} style={{
                                background: 'none', border: 'none'
                            }}>
                                <Close />
                            </button>
                        </div>
                        <ul>
                            {searchResults.map((item) => (
                                <li key={item.inventoryItemId} onClick={() => setSelectedItem(item)}>
                                    {item.productName}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}


                {/* Display DetailView for the selected item */}
                {selectedItem && (
                    <DetailView item={selectedItem} onClose={() => setSelectedItem(null)} />
                )}
            </>
            {/* Main Content Section */}
            <div className="dashboard-main">
                {/* Sidebar */}
                <Sidebar
                    categories={categories}
                    selectedCategory={selectedCategory} // Pass selectedCategory to Sidebar
                    onCategorySelect={handleCategorySelect}
                />


                {/* Central Content */}
                <div className="dashboard-content">
                    {selectedCategory === 'suppliers' ? (
                        <Suppliers /> // Display Suppliers page when 'Suppliers' is selected
                    ) : (
                        <>
                            {/* Dashboard Overview */}
                            <DashboardOverview summaryData={summaryData} />

                            {/* Item List */}
                            <ItemList items={filteredItems} />

                            {/* Reporting Panel */}
                            <ReportingPanel items={items}
                                reports={[
                                    { title: 'Stock In Report', ChartComponent: () => <div>Chart Placeholder</div> },
                                    { title: 'Stock Out Report', ChartComponent: () => <div>Chart Placeholder</div> },
                                    { title: 'Expiry Report', ChartComponent: () => <div>Chart Placeholder</div> },
                                    { title: 'Reorder Report', ChartComponent: () => <div>Chart Placeholder</div> },
                                    //Stock Trends Chart component
                                    { title: 'Stock Trends' },
                                    { title: 'Fast Moving Items', ChartComponent: () => <div>Chart Placeholder</div> },
                                    { title: 'Slow Moving Items', ChartComponent: () => <div>Chart Placeholder</div> },
                                    { title: 'Out of Stock Items', ChartComponent: () => <div>Chart Placeholder</div> },
                                ]}
                                onExport={handleExport}
                            />
                        </>
                    )}
                </div>
            </div>


            {/* Footer */}
            {/* <Footer onAddNew={() => alert('Add New Item')} /> */}
        </div>
    );

}

export default Dashboard;
